import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { logout } from '../auth/auth.slice';

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BACKEND_URL,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem('token');
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const baseQueryWithRedirect = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    api.dispatch(logout());
  };
  return result;
};

export const baseTaksebeQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_TAKSEBE_SHOP_URL
})
