
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import UserHeader from "components/Headers/UserHeader.js";
import { useSelector } from "react-redux";

const Profile = () => {
  const currentUser = useSelector(state => state.users.currentUser);
  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0" id="user-profile">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Мой профиль</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      href="#contact-info"
                      // onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      Основные настройки
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody id="contact-info">
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    Клиентская информация
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Имя
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue=""
                            id="input-first-name"
                            placeholder="Укажите своё имя"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Фамилия
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue=""
                            id="input-last-name"
                            placeholder="Укажите свою фамилию"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Адрес электронной почты
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            defaultValue={currentUser?.user?.email}
                            disabled
                            placeholder={currentUser?.user?.email}
                            type="email"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  {/* Address */}
                  <h6 className="heading-small text-muted mb-4">
                    Контактная информация
                  </h6>
                  <div className="pl-lg-4">
                    <Row className="align-items-center">
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-address"
                          >
                            Аккаунт телеграмм
                          </label>
                          <div className="d-flex">
                            <Input
                              className="form-control-alternative flex-grow-1 mr-2"
                              id="input-address"
                              placeholder="@username"
                              type="text"
                            />
                            <Button onClick={(e) => e.preventDefault()} className="btn btn-primary btn-sm" style={{ border: 'none', outline: 'none', fontSize: '14px' }}>Подтвердить</Button>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-address"
                          >
                            Адрес
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue="ул. Ленина, дом 54"
                            id="input-address"
                            placeholder="Домашний адрес"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-city"
                          >
                            Город
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue="Москва"
                            id="input-city"
                            placeholder="Город"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
