import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import DashboardLayout from "pages/Dashboard.js";
import AuthLayout from "pages/Auth.js";


function App() {
  return (
      <Routes>
        <Route path="/dashboard/*" element={<DashboardLayout />} />
        <Route path="/auth/*" element={<AuthLayout />} />
        <Route path="*" element={<Navigate to="/dashboard/user-profile" replace />} />
      </Routes>
  );
}

export default App;