import React from "react";

import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

function ModalView({modalTitle, children}) {
  const [modalOpen, setModalOpen] = React.useState(false);
  return (
    <>
      <button
        style={{ border: 'none', outline: 'none', fontSize: '14px' }}
        onClick={() => setModalOpen(!modalOpen)}
        className="btn btn-primary btn-sm"
      >
        Добавить категорию
      </button>
      <Modal toggle={() => setModalOpen(!modalOpen)} isOpen={modalOpen}>
        <div className=" modal-header">
          <h5 className=" modal-title" id="exampleModalLabel">
           {modalTitle}
          </h5>
          <button
            aria-label="Close"
            className=" close"
            type="button"
            onClick={() => setModalOpen(!modalOpen)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <ModalBody>{children}</ModalBody>
      </Modal>
    </>
  );
}

export default ModalView;