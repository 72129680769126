import RegisterForm from "containers/RegisterForm";
import RegisterOTPForm from "containers/RegisterOTPForm";
import { getAppStateWithExpiry } from "helpers/appState";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody, Row, Col } from "reactstrap";



const Register = () => {
  const navigate = useNavigate();
  const isAuth = useSelector((state) => state.auth.isAuth);
  useEffect(() => {
    if (isAuth) {
      return navigate("/index");
    }
  }, [isAuth, navigate])
  const [isRegisterSuccess, setIsRegisterSuccess] = useState(false);
  const [email, setEmail] = useState("");

  useEffect(() => {
    const appState = getAppStateWithExpiry("app-state");
    if (appState) {
      if (appState.name === "register-otp" && appState.email) {
        setEmail(appState.email);
        setIsRegisterSuccess(true);
      }
    }
  }, [setEmail])

  return (
    <Col lg="6" md="8">
      <Card className="bg-secondary shadow border-0">
        <CardBody className="px-lg-5 py-lg-5">
          <div className="text-center text-muted mb-4">
            Регистрация
          </div>
          {isRegisterSuccess ? <RegisterOTPForm email={email}/> : <RegisterForm setEmail={setEmail} setIsRegisterSuccess={setIsRegisterSuccess} />}
        </CardBody>
      </Card>
      <Row className="mt-3">
        <Col xs="6">
          <p className="text-light">Уже зарегистрированы?</p>
        </Col>
        <Col className="text-right" xs="6">
          <a
            className="text-light"
            href="#pablo"
            onClick={(e) => e.preventDefault()}
          >
            <Link to={'/auth/login'}>Войти</Link>
          </a>
        </Col>
      </Row>
    </Col>
  );
};

export default Register;
