import React from 'react';
import * as Yup from 'yup';
import { Button, FormGroup, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row, Col } from "reactstrap";
import { authAPI } from "store/reducers/auth/auth.actions";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import SpinnerButton from "components/ui/SpinnerButton";
import { setAppStateWithExpiry } from 'helpers/appState';


const RegisterForm = ({ setEmail, setIsRegisterSuccess }) => {

  const [userRegister, { isLoading }] = authAPI.useUserRegisterMutation();
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Поле обязательно для ввода')
      .email('Почта введена неверно'),
    password: Yup.string()
      .required('Поле обязательно для ввода')
      .matches(/[0-9]/, "Неверный пароль. Должен содержать хотя бы одну цифру (0-9)")
      .matches(/[a-z]/, "Неверный пароль. Должен содержать хотя бы одну заглавную букву (A-Z).")
      .matches(/[A-Z]/, "Неверный пароль. Должен содержать хотя бы одну строчную букву (a-z).")
      .matches(/[!@#$%^&*()_+{}\[\]:;"\'<>,.?/\\|]/, "Неверный пароль. Должен содержать хотя бы один специальный символ."),
    confirmPassword: Yup.string()
      .required('Поле обязательно для ввода')
      .oneOf([Yup.ref('password'), null], 'Пароли не совпадают'),
    acceptTerms: Yup.bool().oneOf([true], 'Подтверждение обязательно')
  });
  const { handleSubmit, control, setError, formState: { errors } } = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });

  const onSubmit = async (data) => {
    const response = await userRegister({
      email: data.email,
      password: data.password,
      password_confirm: data.confirmPassword
    });
    if (response.error) {
      setError("email", { message: response.error.data?.detail })
    } else {
      setEmail(data.email);
      setAppStateWithExpiry("app-state", { "name": "register-otp", "email": data.email })
      setIsRegisterSuccess(true);
    }
  }
  return (
    <Form role="form" onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="email"
        control={control}
        render={({ field, fieldState: { isTouched } }) => (
          <FormGroup className={`${errors?.email ? "has-danger" : (isTouched && !errors.email ? "has-success" : "")}`}>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-email-83" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                {...field}
                name="email"
                value={null}
                placeholder="Почта"
                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                autoComplete="email"
              />
            </InputGroup>
            <div className={`${errors.email ? 'invalid-feedback d-block' : ''}`}>{errors.email?.message}</div>
          </FormGroup>
        )}
      />
      <Controller
        name="password"
        control={control}
        render={({ field, fieldState: { isTouched } }) => (
          <FormGroup className={`${errors?.password ? "has-danger" : (isTouched && !errors.password ? "has-success" : "")}`}>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-key-25" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                {...field}
                value={null}
                placeholder="Пароль"
                type="password"
                className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                autoComplete="password"
              />
            </InputGroup>
            <div className={`${errors.password ? 'invalid-feedback d-block' : ''}`}>{errors.password?.message}</div>
          </FormGroup>
        )}
      />
      <Controller
        name="confirmPassword"
        control={control}
        render={({ field, fieldState: { isTouched } }) => (
          <FormGroup className={`${errors?.confirmPassword ? "has-danger" : (isTouched && !errors.confirmPassword ? "has-success" : "")}`}>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-key-25" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                {...field}
                value={null}
                placeholder="Повторите пароль"
                type="password"
                className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`}
                autoComplete="password"
              />
            </InputGroup>
            <div className={`${errors.confirmPassword ? 'invalid-feedback d-block' : ''}`}>{errors.confirmPassword?.message}</div>
          </FormGroup>
        )}
      />
      <Row className="mt-4">
        <Col xs="12">
          <Controller
            name="acceptTerms"
            control={control}
            render={({ field, fieldState: { isTouched } }) => (
              <FormGroup className="form-group form-check">
                <Input
                  {...field}
                  className={`form-check-input ${errors.acceptTerms ? 'is-invalid' : ''
                    }`}
                  id="customCheckRegister"
                  type="checkbox"
                />
                <label>
                  <span className="text-muted">
                    Я принимаю все условия{" "}
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      Политики конфиденциальности
                    </a>
                  </span>
                </label>
                <div className="invalid-feedback">{errors.acceptTerms?.message}</div>
              </FormGroup>
            )}
          />
        </Col>
      </Row>
      <div className="text-center">
        <SpinnerButton
          children={<Button className="mt-3" color="primary" type="submit">
            Выслать код на почту
          </Button>}
          isLoading={isLoading}
        />
      </div>
    </Form>
  )
}

export default RegisterForm;