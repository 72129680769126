import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../base';

export const authAPI = createApi({
    reducerPath: 'authAPI',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        userPasswordReset: builder.mutation({
            query: (body) => ({
                url: '/accounts/reset-password',
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: body,
            }),
        }),
        userPasswordResetConfirm: builder.mutation({
            query: (body) => ({
                url: '/accounts/reset-password/verify',
                method: 'PATCH',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: body,
            }),
        }),
        userRegister: builder.mutation({
            query: (body) => ({
                url: '/accounts/register',
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: body,
            }),
        }),
        userRegisterVerify: builder.mutation({
            query: (body) => ({
                url: '/accounts/register/verify',
                method: 'PATCH',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: body,
            }),
        }),
        userLogin: builder.mutation({
            query: (postData) => {
                return {
                    url: '/accounts/login',
                    method: 'POST',
                    headers: {
                        'accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: `grant_type=password&username=${postData.email}&password=${postData.password}&scope=&client_id=string&client_secret=string`,
                };
            },
        }),
        userLogout: builder.mutation({
            query: (token) => ({
                url: '/accounts/logout',
                method: 'POST',
                headers: {
                    'accept': '*/*',
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: {},
            }),
        }),
        repeatOtp: builder.mutation({
            query: (body) => ({
                url: '/accounts/otp',
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: body,
            }),
        }),
    }),
});