import React from "react";
import * as Yup from 'yup';
import { Button, FormGroup, Form, Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import { authAPI } from "store/reducers/auth/auth.actions";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { setAuth } from "store/reducers/auth/auth.slice";

import SpinnerButton from "components/ui/SpinnerButton";


const CategoryForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userLogin, { isLoading }] = authAPI.useUserLoginMutation();
  const MAX_FILE_SIZE = 102400; //100KB

  const validFileExtensions = { image: ['jpg', 'gif', 'png', 'jpeg', 'svg', 'webp'] };

  function isValidFileType(fileName, fileType) {
    return fileName && validFileExtensions[fileType].indexOf(fileName.split('.').pop()) > -1;
  }
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Поле обязательно для ввода')
      .email('Почта введена неверно'),
    image: Yup.mixed()
      .test("is-valid-type", "Not a valid image type",
        value => isValidFileType(value?.name && value.name.toLowerCase(), "image"))
      .test("is-valid-size", "Max allowed size is 100KB",
        value => value && value.size <= MAX_FILE_SIZE)
  })
  const { handleSubmit, control, setError, formState: { errors } } = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });

  const onSubmit = async (data) => {
    const response = await userLogin({
      email: data.email,
      password: data.password
    });
    if (response.error) {
      setError("email")
      setError("password", { message: response.error.data?.detail })
    } else {
      dispatch(setAuth({ token: response.data.access_token }))
      navigate('/index');
    }
  }
  return (
    <Form role="form" onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="name"
        control={control}
        render={({ field, fieldState: { isTouched } }) => (
          <FormGroup className={`${errors?.email ? "has-danger" : (isTouched && !errors.email ? "has-success" : "")}`}>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-atom" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                {...field}
                name="name"
                value={null}
                placeholder="Наименование"
                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                autoComplete="email"
              />
            </InputGroup>
            <div className={`${errors.email ? 'invalid-feedback d-block' : ''}`}>{errors.email?.message}</div>
          </FormGroup>
        )}
      />
      <Controller
        name="image"
        control={control}
        render={({ field, fieldState: { isTouched } }) => (
          <FormGroup className={`${errors?.password ? "has-danger" : (isTouched && !errors.password ? "has-success" : "")}`}>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-box-2" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                {...field}
                value={null}
                placeholder="Фото"
                type="file"
                className={`form-control ${errors.password ? 'is-invalid' : ''}`}
              />
            </InputGroup>
            <div className={`${errors.password ? 'invalid-feedback d-block' : ''}`}>{errors.password?.message}</div>
          </FormGroup>
        )}
      />
      <div className="custom-control custom-control-alternative custom-checkbox">
        <input
          className="custom-control-input"
          id="customCheckLogin"
          type="checkbox"
        />
        <label
          className="custom-control-label"
          htmlFor="customCheckLogin"
        >
          <span className="text-muted">Запомнить меня</span>
        </label>
      </div>
      <div className="text-center">
        <SpinnerButton
          isLoading={isLoading}
          children={<Button className="my-4" color="primary" type="submit">Войти </Button>}
        />
      </div>
    </Form>
  )
}

export default CategoryForm;