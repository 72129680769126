import { combineReducers, configureStore } from "@reduxjs/toolkit";

import authSlice from "./reducers/auth/auth.slice";
import usersSlice from "./reducers/users/users.slice";
import { botsAPI } from "./reducers/bots/bots.actions";
import { authAPI } from "./reducers/auth/auth.actions";
import { userAPI } from "./reducers/users/users.actions";
import { taksebeAPI } from "./reducers/taksebe/taksebe.actions";

const rootReducer = combineReducers({
    auth: authSlice,
    users: usersSlice,
    [authAPI.reducerPath]: authAPI.reducer,
    [botsAPI.reducerPath]: botsAPI.reducer,
    [userAPI.reducerPath]: userAPI.reducer,
    [taksebeAPI.reducerPath]: taksebeAPI.reducer
});

export default configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(authAPI.middleware, botsAPI.middleware, userAPI.middleware, taksebeAPI.middleware),
});


