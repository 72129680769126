
import { MobileView, BrowserView, isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { Button, Container, Row, Col } from "reactstrap";
import UserGround from 'assets/img/user/ground.svg'

const UserHeader = () => {
  const currentUser = useSelector(state => state.users.currentUser);
  return (
    <>
      <div
        className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
        style={{
          minHeight: "600px",
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        {/* Mask */}
        <span className="mask bg-gradient-default opacity-8" />
        {/* Header container */}
        <Container className="d-flex align-items-center" fluid>
          <BrowserView>
            <Row>
              <Col lg="6" md="10">
                <h1 className="display-2 text-white">Приветсвуем, {currentUser?.user?.email}</h1>
                <p className="text-white mt-0 mb-5">
                  Это страница вашего профиля. Здесь вы можете изменить основую информацию
                </p>
                <Button
                  color="info"
                  href="#user-profile"
                // onClick={(e) => e.preventDefault()}
                >
                  Изменить данные
                </Button>
              </Col>
              <Col lg="6" md="5">
                <img className="ml-5" width={500} src={UserGround} />
              </Col>
            </Row>
          </BrowserView>
          <MobileView>
            <Row>
              <Col lg="7" md="10">
                {isMobile ? <h1 className="display-3 text-white">Приветсвуем, {currentUser?.user?.email}</h1> : <h1 className="display-2 text-white">Приветсвуем, {currentUser?.user?.email}</h1>}
                <p className="text-white mt-0 mb-5">
                  Это страница вашего профиля. Здесь вы можете изменить основую информацию
                </p>
                <Button
                  color="info"
                  href="#user-profile"
                // onClick={(e) => e.preventDefault()}
                >
                  Изменить данные
                </Button>
              </Col>
            </Row>
          </MobileView>
        </Container>
      </div>
    </>
  );
};

export default UserHeader;
