import React from 'react';
import { Spinner } from 'reactstrap';

const SpinnerComponent = ({ size = 'md', color = 'primary', message = 'Загрузка...' }) => {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100" style={{zIndex: '100', position: 'relative'}}>
      <Spinner color={color} size={size} />
      <div className="ml-3">{message}</div>
    </div>
  );
};

export default SpinnerComponent;