import React from 'react';
import { Spinner, Button } from 'reactstrap';

const SpinnerButton = ({ children, message="Загрузка...", isLoading }) => {
  if (isLoading) {
    return <Button
      color="primary"
      className='my-4'
      disabled
    >
      <Spinner size="sm">
        {message}
      </Spinner>
      <span>
        {' '}{message}
      </span>
    </Button>
  }
  return children;
};

export default SpinnerButton;