import React, { useState } from "react";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table
} from "reactstrap";
import { useGetCategoriesQuery } from "store/reducers/taksebe/taksebe.actions";
import TaksebeHeader from "components/Headers/TaksebeHeader";
import { BrowserView } from "react-device-detect";
import SpinnerComponent from "components/ui/SpinnerComponent";
import ErrorComponent from "components/ui/ErrorComponent";
import ModalView from "components/ui/ModalView";
import CategoryForm from "containers/CategoryForm";

const CategoryList = () => {
  const [page, setPage] = useState(1);
  const [parentId, setParentId] = useState("0");
  const [parentStack, setParentStack] = useState([]);
  const perPage = 25;
  const { data, isLoading, isError, error } = useGetCategoriesQuery({ page, perPage, parentId });

  if (isLoading) {
    return <SpinnerComponent />;
  }

  if (isError) {
    return <ErrorComponent error={error.data} />;
  }

  return (
    <>
      <TaksebeHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col xs={12} className="mb-5 mb-xl-0">
            <Card className="shadow mt-4">
              <CardHeader className="border-0">
                <Row className="align-items-center justify-content-between">
                  <div className="col">
                    <h3 className="mb-0">Категории</h3>
                  </div>
                  <div className="mr-2 d-flex align-items-center">
                    {parentStack.length > 0 && (
                      <button
                      onClick={() => {
                        const prevParentId = parentStack.pop();
                        setParentStack([...parentStack]);
                        setParentId(prevParentId);
                      }}
                      style={{ border: 'none', outline: 'none', fontSize: '14px' }}
                      className="btn btn-warning btn-sm"
                    >
                      Назад
                    </button>
                      
                    )}
                    <ModalView 
                    modalTitle="Добавление категории"
                    children={<CategoryForm />}
                      Добавить категорию
                    />
                    <i className="ni ni-air-baloon" id="tooltipTarget"></i>
                    <UncontrolledTooltip placement="top" target="tooltipTarget">
                      Выберите нужную категорию и измените её, либо добавьте новый товар
                    </UncontrolledTooltip>
                  </div>
                </Row>
              </CardHeader>
              <BrowserView>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Наименование</th>
                      <th scope="col">Создана</th>
                      <th scope="col">Обновлена</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.items.map((item) => (
                      <tr key={item.category_id}>
                        <th scope="row">{item.name}</th>
                        <td>{item.created_at}</td>
                        <td>{item.updated_at}</td>
                        <td>
                          {item.has_children ? (
                            <button
                            onClick={() => {
                              setParentStack([...parentStack, parentId]);
                              setParentId(item.category_id);
                            }}
                            style={{ border: 'none', outline: 'none', fontSize: '14px' }}
                            className="btn btn-warning btn-sm"
                          >
                            Дочерние категории
                          </button>
                          ) : (
                            <button
                              style={{ border: 'none', outline: 'none', fontSize: '14px' }}
                              className="btn btn-primary btn-sm"
                            >
                              К товарам
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </BrowserView>
              <Pagination className="mt-4 d-flex justify-content-center align-items-center">
                {data.previous_page && (
                  <PaginationItem>
                    <PaginationLink previous onClick={() => setPage(page - 1)} />
                  </PaginationItem>
                )}
                <PaginationItem>
                  <PaginationLink >
                    {page}
                  </PaginationLink>
                </PaginationItem>
                {data.next_page && (
                  <PaginationItem>
                    <PaginationLink next onClick={() => setPage(page + 1)} />
                  </PaginationItem>
                )}
              </Pagination>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CategoryList;
