import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithRedirect } from '../base';


export const botsAPI = createApi({
  reducerPath: 'botsAPI',
  baseQuery: baseQueryWithRedirect,
  endpoints: (builder) => ({
    createBot: builder.mutation({
      query: (body) => ({
        url: '/analytics/bots/',
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: body,
      }),
      invalidatesTags: ['Bots'],
    }),
    getBotInfo: builder.mutation({
      query: () => ({
        url: '/analytics/bots/',
        method: 'GET',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json'
        },
      }),
    }),
    getAllBots: builder.query({
      query: (body) => ({
        url: '/analytics/bots/all/',
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'accept': 'application/json',
          'Content-Type': 'application/json'
        },
      }),
      providesTags: result => ['Post']
    }),
    updateUserBots: builder.mutation({
      query: () => ({
        url: '/analytics/bots/user/',
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json'
        },
      }),
    }),
    editActiveUser: builder.mutation({
      query: (user_id) => ({
        url: `/analytics/bots/user/${user_id}/`,
        method: 'PATCH',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json'
        },
      }),
    }),
  })
});


export const {useGetAllBotsQuery} = botsAPI;