import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithRedirect } from '../base';

export const userAPI = createApi({
  reducerPath: 'userAPI',
  baseQuery: baseQueryWithRedirect,
  endpoints: (builder) => ({
    userMe: builder.query({
      query: () => ({
        url: '/accounts/me',
        method: 'GET',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json'
        },
      }),
    }),
  }),
});

export const {useUserMeQuery} = userAPI;