import Profile from "views/Profile";
import BotList from "views/Bot/BotList";
import AuthRequired from "views/AuthRequired";
import Login from "views/Login";
import Register from "views/Register";
import CategoryList from "views/Taksebe/Category/CategoryList";
var routes = [
  {
    path: "/bots",
    name: "Боты (Telegram)",
    icon: "ni ni-tv-2 text-primary",
    component: <AuthRequired children={<BotList />}/>,
    layout: "/dashboard",
  },
  // {
  //   path: "/bot/:uid",
  //   name: "Боты (Telegram)",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: <AuthRequired children={<BotDetail />}/>,
  //   layout: "/dashboard",
  // },
  {
    path: "/subscription",
    name: "Подписка",
    icon: "ni ni-cart text-orange",
    component: <AuthRequired children={<Profile />}/>,
    layout: "/dashboard",
  },
  {
    path: "/user-profile",
    name: "Профиль",
    icon: "ni ni-single-02 text-yellow",
    component: <AuthRequired children={<Profile />}/>,
    layout: "/dashboard",
  },
  {
    path: "/login",
    name: "Вход",
    icon: "ni ni-key-25 text-info",
    component: <Login />,
    layout: "/auth",
  },
  {
    path: "/register",
    name: "Регистрация",
    icon: "ni ni-circle-08 text-pink",
    component: <Register />,
    layout: "/auth",
  },
  {
    path: "/taksebe/category",
    name: "ТакСебе (витрина)",
    icon: "ni ni-cart text-orange",
    component: <AuthRequired children={<CategoryList />}/>,
    layout: "/dashboard",
  },
];
export default routes;
