import React from "react";
import { Button, UncontrolledTooltip, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Table

} from "reactstrap";

import { BrowserView, MobileView } from 'react-device-detect';
import Header from "components/Headers/BotHeader.js";


import { useGetAllBotsQuery } from "store/reducers/bots/bots.actions";
import { Link } from "react-router-dom";
import SpinnerComponent from "components/ui/SpinnerComponent";
import Telemessage from "containers/Telemessage";

const BotList = () => {
  const { data: bots, isLoading, isError, error } = useGetAllBotsQuery();
  if (isLoading) {
    return <SpinnerComponent size="lg" color="primary" message="Загрузка..." />
  }
  if (isError) {
    return <p>{error.error}</p>
  }
  const totalUsers = bots.reduce((acc, bot) => acc + bot.total_users, 0);
  const totalDayUsers = bots.reduce((acc, bot) => acc + bot.day_users, 0);
  const totalInactiveUsers = bots.reduce((acc, bot) => acc + bot.inactive_users, 0);
  const totalActiveUsers = totalUsers - totalInactiveUsers;

  return (
    <>
      <Header 
        totalUsers={totalUsers} 
        totalInactiveUsers={totalInactiveUsers} 
        totalActiveUsers={totalActiveUsers} 
        totalDayUsers={totalDayUsers} />
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col xs={12} className="mb-5 mb-xl-0">
            <Card className="shadow mt-4">
              <CardHeader className="border-0">
                <Row className="align-items-center justify-content-between">
                  <div className="col">
                    <h3 className="mb-0">Боты</h3>
                  </div>
                  <div className="mr-2 d-flex align-items-center">
                    <button
                      style={{ border: 'none', outline: 'none', fontSize: '14px' }}
                      className="btn btn-primary btn-sm">
                      Создать бота
                    </button>
                    <i className="ni ni-air-baloon" id="tooltipTarget"></i>
                    <UncontrolledTooltip placement="top" target="tooltipTarget">
                      Здесь информация о том как и зачем это работает
                    </UncontrolledTooltip>
                  </div>
                </Row>
              </CardHeader>
              <BrowserView>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Имя пользователя</th>
                      <th scope="col">Пользователей</th>
                      <th scope="col">Активные пользователи</th>
                      <th scope="col">Неактивные пользователи</th>
                      <th scope="col">Пользователи за сутки</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {bots.map((bot) => (
                      <tr key={bot.id}>
                        <th scope="row">{bot.name}</th>
                        <td>{bot.total_users}</td>
                        <td>{bot.total_users - bot.inactive_users}</td>
                        <td>{bot.inactive_users}</td>
                        <td>{bot.day_users}</td>
                        <td>
                          <Link to={'/admin/index/bot'}>Узнать подробнее</Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </BrowserView>
              <MobileView>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Имя пользователя</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {bots.map((bot) => (
                      <tr key={bot.id}>
                        <th scope="row">{bot.name}</th>
                        <td>
                          <Button className="btn btn-primary btn-sm" style={{ border: 'none', outline: 'none', fontSize: '14px' }} to={'/admin/index/bot'}>Перейти к боту</Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </MobileView>
              {/* пагинация */}
              <Pagination className="mt-4 d-flex justify-content-center align-items-center">
                <PaginationItem>
                  <PaginationLink previous />
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink >
                    {1}
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink next />
                </PaginationItem>
              </Pagination>
            </Card>
          </Col>
        </Row>
      </Container>
      <Telemessage />
    </>
  );
};

export default BotList;
