import React from "react";
import * as Yup from 'yup';
import { Button, FormGroup, Form, Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import { authAPI } from "store/reducers/auth/auth.actions";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import SpinnerButton from "components/ui/SpinnerButton";
import { useDispatch } from "react-redux";
import { setAuth } from "store/reducers/auth/auth.slice";

const RegisterOTPForm = ({ email }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userRegisterVerify, { isLoading: isVerifyLoading }] = authAPI.useUserRegisterVerifyMutation();
  const [repeatOtp, { }] = authAPI.useRepeatOtpMutation();
  const validationSchema = Yup.object().shape({
    otp: Yup.string()
      .required('Поле обязательно для ввода')
      .length(6, 'Код должен состоять из 6 цифр'),
  });
  const { handleSubmit, control, setError, formState: { errors } } = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });
  const onSubmit = async (data) => {
    const response = await userRegisterVerify({
      email: email,
      otp: data.otp,
    })
    if (response.error) {
      setError("otp", { message: response.error.data?.detail })
    } else {
      dispatch(setAuth({ token: response.data.access_token }))
      navigate('/index');
    }
  }
  const sendNewOtp = async (e) => {
    e.preventDefault();
    const response = await repeatOtp({
      request_type: "register",
      email: email,
    });
    if(response.error) {
      setError("otp", {message: response.error.data?.detail})
    }
  }
  return (
    <Form role="form" onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="otp"
        control={control}
        render={({ field, fieldState: { isTouched } }) => (
          <FormGroup className={`${errors?.otp ? "has-danger" : (isTouched && !errors.otp ? "has-success" : "")}`}>
            <InputGroup className="input-group-alternative" style={{ width: '90%' }}>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-lock-circle-open" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                {...field}
                value={null}
                placeholder="Введите код, отправленный вам на почту"
                type="text"
                autoComplete="otp-code"
              />
              <div className="" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#fff', borderRadius: '0 0.375rem 0.375rem 0' }}>
                <button className="" style={{ border: 'none', outline: 'none', backgroundColor: 'transparent' }} onClick={sendNewOtp}>
                  <img src="/reload.svg" alt="Reload" style={{ width: '16px', height: '16px', color: '#adb5bd' }} />
                </button>
              </div>
            </InputGroup>
            <div className={`${errors.otp ? 'invalid-feedback d-block' : ''}`}>{errors.otp?.message}</div>
          </FormGroup>
        )}
      />
      <div className="text-center">
        <SpinnerButton
          children={<Button className="mt-3" color="primary" type="submit">
            Подтвердить регистрацию
          </Button>}
          isLoading={isVerifyLoading}
        />
      </div>
    </Form>
  )

}

export default RegisterOTPForm;