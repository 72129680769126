import { createSlice } from '@reduxjs/toolkit';
import { setAppState, getAppState } from 'helpers/appState';

let currentUser = getAppState("current-user");


const initialState = {
    currentUser,
}

export const usersSlice = createSlice({
    name: 'usersSlice',
    initialState,
    reducers: {
        setCurrentUser: (state, action) => {
            state.currentUser = action.payload;
            setAppState("current-user", action.payload)
        },
    }
})

export const { setCurrentUser } = usersSlice.actions;
export default usersSlice.reducer; 