import { createApi } from '@reduxjs/toolkit/query/react';
import { baseTaksebeQuery } from '../base';

export const taksebeAPI = createApi({
  reducerPath: 'taksebeAPI',
  baseQuery: baseTaksebeQuery,
  endpoints: (builder) => ({
    getProducts: builder.query({
      query: (data) => ({
        url: `/products/?page=${data.page}&per_page=${data.perPage}&categoryId=${data.categoryId}`,
        method: 'GET',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['Product'],
    }),
    getCategories: builder.query({
      query: (data) => ({
        url: `/categories/?page=${data.page}&per_page=${data.perPage}&parent_id=${data.parentId}`,
        method: 'GET',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['Category'],
    })
  })
});


export const { useGetCategoriesQuery, useGetProductsQuery } = taksebeAPI;