import React from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";
import { useDispatch } from "react-redux";
import { setCurrentUser } from "store/reducers/users/users.slice";
import { useUserMeQuery } from "store/reducers/users/users.actions";
import SpinnerComponent from "components/ui/SpinnerComponent";
import { getCurrentRoute } from "helpers/routes";
import { getRoutes } from "helpers/routes";

const DashboardLayout = (props) => {
  const location  = useLocation();
  const dispatch = useDispatch();
  const mainContent = React.useRef(null);
  const {data: user, isLoading, isError} = useUserMeQuery();
  if (isLoading) {
      return <SpinnerComponent />
  }
  if (!isError) {
    dispatch(setCurrentUser(user))
  }
  const currentRoute = getCurrentRoute(routes, location.pathname);

  return (
    <>
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/dashboard/bots",
          imgSrc: require("../assets/img/brand/argon-react.png"),
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={currentRoute?.name}
        />
        <Routes>
          {getRoutes(routes, "/dashboard")}
          <Route path="*" element={<Navigate to="/dashboard/bots" replace />} />
        </Routes>
      </div>
    </>
  );
};

export default DashboardLayout;
