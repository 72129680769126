import { createSlice } from '@reduxjs/toolkit';
import { isAuthenticated } from "helpers/auth";


let isAuth = isAuthenticated();


const initialState = {
    isAuth,
}

export const userSlice = createSlice({
    name: 'authSlice',
    initialState,
    reducers: {
        logout: (state, action) => {
            state.isAuth = false;
            localStorage.clear();
        },
        setAuth: (state, action) => {
            state.isAuth = true;
            localStorage.setItem("token", action.payload.token);
        },
    }
})

export const { setAccessToken, logout, setAuth } = userSlice.actions;
export default userSlice.reducer; 