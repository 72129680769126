import React, { useEffect } from "react";

import { Card, CardBody, Row, Col } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";

import LoginForm from "containers/LoginForm";
import { useSelector } from "react-redux";

const Login = () => {
  const navigate = useNavigate();
  const isAuth = useSelector((state) => state.auth.isAuth);
  useEffect(() => {
    if (isAuth) {
      return navigate("/index");
    }
  }, [isAuth, navigate])
  return (
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              Авторизация
            </div>
            <LoginForm />
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <a
              className="text-light"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              <Link to={'/auth/forget-password'}>Забыли пароль?</Link>
            </a>
          </Col>
          <Col className="text-right" xs="6">
            <a
              className="text-light"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              <Link to={'/auth/register'}>Зарегистрироваться</Link>
            </a>
          </Col>
        </Row>
      </Col>
  );
};

export default Login;
