import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const AuthRequired = ({ children }) => {
  const navigate = useNavigate();
  const {isAuth} = useSelector(state => state.auth);
  useEffect(() => {
    if (!isAuth) {
      navigate("/auth/");
    }
  }, [navigate, isAuth])

  return children;
};

export default AuthRequired;