import { Link } from "react-router-dom";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";

import { authAPI } from "store/reducers/auth/auth.actions";
import { useDispatch } from "react-redux";
import { logout } from "store/reducers/auth/auth.slice";

const AdminNavbar = (props) => {
  const [userLogout, { }] = authAPI.useUserLogoutMutation()
  const token = localStorage.getItem('token');
  const dispatch = useDispatch();
  const handleLogin = async () => {
    const response = await userLogout(token);
    if (response.error) {
      console.log(response.error)
    }
    dispatch(logout());
  };

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <Link
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to="/"
          >
            {props.brandText}
          </Link>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  {/* <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={require("../../assets/img/theme/team-4-800x800.jpg")}
                    />
                  </span> */}
                  <Media className="ml-2 d-none d-lg-block">
                    <span  className="mb-0 text-lg font-weight-bold">
                      {/* Добро пожаловать, {currentUser.user.first_name && currentUser.user.first_name ?
                        currentUser.user.first_name + ' ' + currentUser.user.last_name : currentUser.user.email} */}
                        <i className="ni ni-single-02" />
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem to="/dashboard/user-profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>Мой профиль</span>
                </DropdownItem>
                <DropdownItem target="_blank" to="https://t.me/bratwa_support" tag={Link}>
                  <i className="ni ni-support-16" />
                  <span>Помощь</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={handleLogin}>
                  <i className="ni ni-user-run" />
                  <span>Выйти</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
