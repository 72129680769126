import React from 'react';

const ErrorComponent = ({error = 'Произошла ошибка. Попробуйте позже...' }) => {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100" style={{zIndex: '100', position: 'relative'}}>
      <div className="ml-3">{error}</div>
    </div>
  );
};

export default ErrorComponent;