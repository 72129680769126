import { Route } from "react-router-dom";


export const getRoutes = (routes, layout) => {
  return routes.map((prop, key) => {
    if (prop.layout === layout) {
      return (
        <Route path={prop.path} element={prop.component} key={key} exact />
      );
    } else {
      return null;
    }
  });
};
export const getCurrentRoute = (routes, pathname) => {
  return routes.find(route => route.layout + route.path === pathname)
};