import React, { useEffect, useRef } from "react";
import EditorJS from "@editorjs/editorjs";
import Quote from '@editorjs/quote';
import { toHtml } from "helpers/html-parser";

const Telemessage = () => {
    const ejInstance = useRef();

    const initEditor = () => {
        const editor = new EditorJS({
            holder: 'editorjs',
            autofocus: true,
            tools: {
                quote: Quote
            },
            onChange: async () => {
                let content = await editor.saver.save();
            }
        });
        ejInstance.current = editor;
    };

    // Это будет запущено только один раз
    useEffect(() => {
        if (ejInstance.current === null) {
            initEditor();
        }

        return () => {
            ejInstance?.current?.destroy();
            ejInstance.current = null;
        };
    }, []);

    return <div id='editorjs'></div>;
};

export default Telemessage;
