import { jwtDecode } from 'jwt-decode';


export const isAuthenticated = () => {
  const currentTimestamp = Math.floor(Date.now() / 1000);
  const accessToken = localStorage.getItem("token");
  if (!accessToken) {
    return false;
  }
  try {
    var userData = jwtDecode(accessToken);
    const isTokenExpired = currentTimestamp >= userData.exp;
    if (!isTokenExpired) {
      return true;
    }
  } catch (error) {
    return false;
  }
  return false;
}